import React from "react";
import AppWrap from "./wrapper/AppWrap";
import MotionWrap from "./wrapper/MotionWrap";
import linkedin from "../assets/LinkedIn-icon.png";
// import twitter from "../assets/Twitter-icon.png";
//import skype from "../assets/Skype-icon.png";
import github from "../assets/GitHub-icon.png";


const Contact = () => {



  return (
    <div className="container mx-auto md:py-10 py-6 md:px-5 px-3">
      <div className="grid grid-cols-1 lg:grid-cols-12  space-y-6 lg:space-y-0  ">
        <div className=" lg:col-span-4 flex flex-col justify-center items-start  contact_bg_1 h-full gap-5 md:px-8 px-1 py-8">
          <h2 className="md:text-3xl  sm:text-2xl text-gray-700 text-xl font-semibold font-Roboto px-5 ">
            Could I build your next website?
          </h2>
          <p className="md:text-2xl   sm:text-xl text-xl  text-gray-600 font-semibold font-Roboto px-5 ">
            Contact me to start the discussion
          </p>
        </div>
        <div className="lg:col-span-5 flex  flex-col justify-center  items-start lg:px-5 px-2 lg:py-5  ">
           
            <div>
              <h2 className=" text-xl pb-5">
                Please send me a message and I will get back to you soon!
              </h2>
              <a href="https://blog.shubhra.dev/contact/">
              <button className="btn font-semibold"  >SEND MESSAGE</button></a>
            </div>
          
        </div>

        <div className="  lg:col-span-3    md:flex  flex-col justify-center items-center contact_bg_3  py-5 ">
          <a
            href="https://www.linkedin.com/in/devshubhra/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex flex-row justify-center items-center gap-3 shared-icons-div">
              <div className=" h-12  w-12    cursor-pointer  flex justify-center items-center shared-icons">
                <img
                  src={linkedin}
                  alt="linkedin"
                  height="20px"
                  width="20px"
                  // border-dashed border-8 border-indigo-900
                  className="   "
                />
              </div>

              <p>Linkedin</p>
            </div>
          </a>
          {/* <a
            href="skype:live:shubhrapokhariya"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex flex-row justify-center items-center gap-3 shared-icons-div">
              <div className=" h-12  w-12    cursor-pointer flex justify-center items-center shared-icons">
                <img
                  src={skype}
                  alt="skype"
                  height="25px"
                  width="25px"
                  // border-dashed border-8 border-indigo-900
                  className="   "
                />
              </div>

              <p>Skype</p>
            </div>
          </a> */}
          <a
            href="https://github.com/shubhrapokhariya"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex flex-row justify-center items-center gap-3 shared-icons-div">
              <div className=" h-12  w-12    cursor-pointer  flex justify-center items-center shared-icons">
                <img
                  src={github}
                  alt="github"
                  height="20px"
                  width="20px"
                  // border-dashed border-8 border-indigo-900
                  className="   "
                />
              </div>

              <p>Github</p>
            </div>
          </a>
          {/* <a href="https://twitter.com/" target="_blank" rel="noreferrer">
            <div className="flex flex-row justify-center items-center gap-3 shared-icons-div">
              <div className=" h-12  w-12    cursor-pointer  flex justify-center items-center shared-icons">
                <img
                  src={twitter}
                  alt="twitter"
                  height="28px"
                  width="28px"
                  // border-dashed border-8 border-indigo-900
                  className="   "
                />
              </div>

              <p>Twitter</p>
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Contact), "Contact", "web__bg");
