import React from "react";
import shubhra from "../assets/shubh.png";
import rhombus from "../assets/rhombus.png";
import { motion } from "framer-motion";
import MotionWrap from "./wrapper/MotionWrap";
import AppWrap from "./wrapper/AppWrap";
import linkedin from "../assets/LinkedIn-icon.png";
// import twitter from "../assets/Twitter-icon.png";
//import skype from "../assets/Skype-icon.png";
import github from "../assets/GitHub-icon.png";

const Home = () => {
  // lg:py-32
  return (
    <div className="container mx-auto md:py-10 py-6 lg:px-10  px-10 ">
      <div className="grid grid-cols-1 lg:grid-cols-12 justify-center items-center lg:space-x-20 ">
        <div className=" lg:col-span-4   flex flex-col justify-center  items-center  text-center">
          <div className="relative ">
            <div>
              <h1 className=" z-20  text-4xl md:text-6xl sm:text-5xl  font-semibold font-Roboto  text-transparent  bg-clip-text bg-gradient-to-r from-purple-400 via-purple-300 to-blue-600	">
                Welcome
                <span> !</span>
              </h1>
            </div>
            <img
              src={shubhra}
              alt="shubhra"
              width="400px"
              height="350px"
              // border-dashed border-8 border-indigo-900
              className="relative md:pt-16 pt-6 "
            />

            <div className="blur bluri bg-themeDarkPurple opacity-70 md:opacity-20 top-32  left-56 md:w-96 md:h-96 sm:w-20 sm:h-28 w-2 h-2"></div>
          </div>
        </div>
        <div className=" lg:col-span-4    flex flex-col  justify-center items-center  lg:px-0 lg:py-0  sm:py-8 sm:px-2 py-8">
          <div className=" space-y-2   lg:text-6xl  font-Roboto ">
            <p className="md:text-6xl  sm:text-5xl  text-4xl  font-semibold font-Roboto">
              Shubhra
            </p>
            <p className="md:text-4xl sm:text-3xl text-2xl ">Web Developer</p>
            <p className="md:text-2xl sm:text-xl text-lg  md:pt-4 sm:pt-2">{`<probably yours>`}</p>
          </div>
          <div className="flex lg:pt-20 pt-5">
            <a
              href="https://www.linkedin.com/in/devshubhra/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="h-12  w-12    cursor-pointer  flex justify-center items-center shared-icons-div">
                <img
                  src={linkedin}
                  alt="linkedin"
                  height="20px"
                  width="20px"
                  className="shared-icons   "
                />
              </div>
            </a>
            {/* <a
              href="skype:live:shubhrapokhariya"
              target="_blank"
              rel="noreferrer "
            >
              <div className=" h-12  w-12    cursor-pointer flex justify-center items-center shared-icons-div">
                <img
                  src={skype}
                  alt="skype"
                  height="25px"
                  width="25px"
                  className="shared-icons"
                />
              </div>
            </a> */}
            <a
              href="https://github.com/shubhrapokhariya"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" h-12  w-12    cursor-pointer  flex justify-center items-center shared-icons-div">
                <img
                  src={github}
                  alt="github"
                  height="20px"
                  width="20px"
                  className="shared-icons"
                />
              </div>
            </a>
            {/* <a href="https://twitter.com/" target="_blank" rel="noreferrer">
              <div className=" h-12  w-12    cursor-pointer  flex justify-center items-center shared-icons-div">
                <img
                  src={twitter}
                  alt="twitter"
                  height="28px"
                  width="28px"
                  className="shared-icons"
                />
              </div>
            </a> */}
          </div>
        </div>

        <div className="lg:col-span-4  menuItems lg:px-0 lg:py-0  sm:py-6 sm:px-16 py-5 px-1 ">
          <motion.div
            initial={{ rotate: 45 }}
            whileInView={{ rotate: 0 }}
            // viewport={{ margin: "-40px" }}
            transition={{ duration: 3.5, type: "spring" }}
            className="   font-semibold relative lg:text-xl md:text-2xl text-sm"
          >
            <img src={rhombus} alt="shubhra" className="w-full " />

            <a
              href={`#Skills`}
              key={`link-Skills`}
              className=" font-Roboto menu-a-1 "
            >
              Skills
            </a>

            <a
              href={`#Projects`}
              key={`link-Projects`}
              className=" font-Roboto  menu-a-2"
            >
              Projects
            </a>
            
            <a
              // href={`#Contact`}
              // key={`link-Contact`}
              href="https://blog.shubhra.dev/contact/"
            
              className=" font-Roboto  menu-a-3"
            >
              Contact
            </a>

            <a
              href={`#Testimonials`}
              key={`link-Testimonials`}
              className=" font-Roboto  menu-a-4"
            >
              Testimonials
            </a>
          </motion.div>

          <div className="blur absolute bg-themeDarkPurple opacity-70 md:opacity-30  sm:top-56 sm:right-48 bottom-52 right-36 md:right-96 md:top-96  md:w-80 md:h-44 w-44 h-28 "></div>
          <div className="blur absolute md:right-72 md:top-20 right-56 top-10 md:w-80 md:h-44 w-28 h-28  bg-themeDarkBlue opacity-70 md:opacity-40 "></div>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Home), "Home", "home__bg");
